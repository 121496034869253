import { Injectable } from "@angular/core";
import { OAuthService, JwksValidationHandler, AuthConfig } from "angular-oauth2-oidc";
import { Router } from "@angular/router";
import { FormGroupDirective } from "@angular/forms";
import { SocketUtilsService } from "./socket-utils.service";
import { Observable } from "rxjs";
import { NotificationService } from "./notification.service";
import { GlobalService } from "./global.service";

@Injectable({
  providedIn: "root"
})
export class OauthClientService {
  authPasswordFlowConfig: AuthConfig = {
    clientId: "anm.portal",
    scope: "openid anm.profile anm.roles anm.staff.departments offline_access email",
    showDebugInformation: true,
    oidc: false,
    issuer: this.globalService.oAuthIssuer
  };

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private socketUtils: SocketUtilsService,
    private notificationService: NotificationService,
    private globalService: GlobalService
  ) {
    this.oauthService.configure(this.authPasswordFlowConfig);
    this.oauthService.loadDiscoveryDocument();
    this.oauthService.requireHttps = false;
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
  }

  login(username: string, password: string, formDirective?: FormGroupDirective) {
    this.oauthService.fetchTokenUsingPasswordFlow(username, password).then(
      token => {
        this.oauthService.loadUserProfile().then(res => {
          var use_portal = res["use_portal"];
          if (use_portal == "true" ? true : false) {
            this.socketUtils.connectSocketOnLogin();
            this.router.navigate(["/aditask"]);
          } else {
            sessionStorage.clear();
            this.notificationService.showNotification("warning", "Failed to login", "Seems that you don't have access to this website..");
          }
        });
      },
      error => {
        if (formDirective) formDirective.resetForm();
      }
    );
  }

  public logout() {
    this.oauthService.logOut(true);
    this.router.navigate([""]);
  }

  accessToken() {
    return this.oauthService.getAccessToken();
  }

  private getAccessTokenExpiration() {
    return this.oauthService.getAccessTokenExpiration();
  }

  isTokenExpired() {
    var currentDate = new Date();
    if (this.getAccessTokenExpiration() <= currentDate.getTime()) return true;
    return false;
  }

  refreshToken(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.oauthService
        .refreshToken()
        .then(response => {
          this.oauthService.loadUserProfile().then(res => {
            resolve(response["access_token"]);
          });
        })
        .catch(error => reject(error));
    });
  }
}
