import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-evacuation-route',
  templateUrl: './evacuation-route.component.html',
  styleUrls: ['./evacuation-route.component.scss']
})
export class EvacuationRouteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
