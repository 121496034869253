import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { Message, notificationMessage } from "../model/chat";

@Injectable({
  providedIn: "root",
})
export class GlobalService {
  headers = new HttpHeaders({
    "Content-Type": "application/json",
  });

  environment: "PRODUCTION" | "DEVELOPMENT" = "DEVELOPMENT";
  PROJECT: "ANM" | "ADI" = "ADI";
  MARINA_CONF = {
    apiURL: this.environment == "DEVELOPMENT" ? "https://anm.aditess.com/api" : "https://anmapi.addimarine.com/api",
    socketURL: this.environment == "DEVELOPMENT" ? "https://ws.aditess.com/adimarine" : "https://ws.addimarine.com/adimarine",
    oAuthIssuer: this.environment == "DEVELOPMENT" ? "https://auth.aditask.com" : "https://auth.addimarine.com",
    modelPrefix: this.environment == "DEVELOPMENT" ? "adimarine" : "aditask",
    projectName: ["add", "Marine"],
  };

  ADITASK_CONF = {
    apiURL: this.environment == "DEVELOPMENT" ? "https://api.aditask.com/api" : "https://api.aditask.com/api",
    socketURL: this.environment == "DEVELOPMENT" ? "https://ws.aditess.com/adimarine" : "https://ws.aditess.com/adimarine",
    oAuthIssuer: this.environment == "DEVELOPMENT" ? "https://auth.aditask.com" : "https://auth.aditask.com",
    modelPrefix: "aditask",
    projectName: ["add", "Task"],
  };

  apiURL = this.PROJECT == "ANM" ? this.MARINA_CONF.apiURL : this.ADITASK_CONF.apiURL;
  socketURL = this.PROJECT == "ANM" ? this.MARINA_CONF.socketURL : this.ADITASK_CONF.socketURL;
  modelPrefix = this.PROJECT == "ANM" ? this.MARINA_CONF.modelPrefix : this.ADITASK_CONF.modelPrefix;
  oAuthIssuer = this.PROJECT == "ANM" ? this.MARINA_CONF.oAuthIssuer : this.ADITASK_CONF.oAuthIssuer;
  projectName = this.PROJECT == "ANM" ? this.MARINA_CONF.projectName : this.ADITASK_CONF.projectName;

  // Development IP
  // apiURL = "http://localhost:8005/api";
  // socketURL = 'http://192.168.10.37:8002';
  // modelPrefix = "adimarine";

  evacuationURL = "https://evacuation.aditess.com/api";

  messages: Array<Message> = [];
  report_icon: string = "assets/marker-icon.png";
  event_icon: string = "assets/marker-icon-red.png";
  task_icon: string = "assets/marker-icon-green.png";
  resource_icon: string = "assets/marker-icon-violet.png";

  constructor() {}
}
