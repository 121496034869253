import { Injectable } from '@angular/core';
import { WebSocketService } from './web-socket.service';
import { ApiServiceService } from './api-service.service';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SocketUtilsService {

  private isSocketConnected$: BehaviorSubject<Boolean> = new BehaviorSubject(null);
  socketConnected = this.isSocketConnected$.asObservable();

  constructor(
    private socketService: WebSocketService,
    private appServiceAPi: ApiServiceService
  ) { }

  connectSocketOnLogin() {
    this.appServiceAPi.getPortalSuperUserInfo().pipe(filter(response => response != null)).subscribe((res) => {
      this.socketService.getConnection(res['data']['socketid']);
      this.isSocketConnected$.next(true);
    })
  }

  closeSocketOnLogout() {
    this.socketService.disconnect();
  }

}
