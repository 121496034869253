import { Injectable } from '@angular/core';
import io from 'socket.io-client';
import { Observable } from 'rxjs';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {

  constructor(
    private global: GlobalService
  ) { }

  socket: any;

  public getConnection(channel: string): any {
    this.socket = io.connect(this.global.socketURL);
    this.socket.emit('subscribe', channel);
    return this.socket;
  }

  public write(topic: string, message: any, socket: any) {
    socket.emit(topic, message);
  }

  public read(topic: string): Observable<any> {
    return Observable.create((observer) => {
      this.socket.on(topic, (message) => {
        observer.next(message);
      });
    });
  }

  public stopReading(topic: string, listener: any) {
    // this.socket.removeAllListeners(topic);
    this.socket.removeListener(topic, listener)
  }


  public disconnect() {
    this.socket.disconnect();
  }
}
