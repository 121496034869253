import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Notification } from '../model/notification';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {

  constructor(
    private toast: ToastrService,
  ) { }

  public showNotification(type: 'info' | 'success' | 'warning' | 'error', title: string, message: string, notification?: Notification): any {
    switch (type) {
      case 'info':
        return this.toast.info(message, title).onTap;
        break;
      case 'success':
        this.toast.success(message, title);
        break;
      case 'warning':
        this.toast.warning(message, title);
        break;
      case 'error':
        this.toast.error(message, title);
        break;
    }
  }
}
