<div class="page-container" fxLayoutAlign="center center">

  <mat-card fxLayout="row" fxLayoutAlign="center center">
    <div class="left-side" fxLayout="column" fxLayoutAlign="center center">
      <!-- <span>Welcome to </span> -->
      <span class="project-name">{{globalService.projectName[0]}}<span
          class="task">{{globalService.projectName[1]}}</span></span>
      <!-- <img src="https://aditess.com/main/wp-content/uploads/2015/08/logov5.png" alt="Aditess Logo" /> -->
    </div>

    <div class="right-side">
      <form class="login-form" [formGroup]="loginForm" #formDirective="ngForm"
        (ngSubmit)="login(loginForm, formDirective)" fxLayout="column" fxLayoutAlign="start center">
        <div style="width: 100%;">
          <div class="form-group">
            <label for="username">Username</label>
            <input formControlName="username" class="form-control" id="username" aria-describedby="usernameHelp"
              placeholder="Enter username">
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input formControlName="password" type="password" class="form-control" id="password" placeholder="Password">

            <mat-error *ngIf="submitted && f.password.hasError('password') && !f.password.hasError('required')">
              Please enter a valid password address
            </mat-error>
            <mat-error *ngIf="submitted && f.password.hasError('required')">
              Password is <strong>required</strong>
            </mat-error>
          </div>
        </div>
        <button type="submit" class="btn btn-primary">Login</button>
      </form>
    </div>
  </mat-card>

</div>