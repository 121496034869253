import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { OauthClientService } from '../service/oauth-client.service';
import { request } from 'http';

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {

  authService: OauthClientService = null;
  isRefreshing: boolean = false;
  requestNumber: number = 0;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    inj: Injector,
    private router: Router
  ) {
    setTimeout(() => {
      this.authService = inj.get(OauthClientService);
    })
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401) {
        return this.handle401Error(request, next);
      } else {
        const error = err.error.message || err.statusText;
        return throwError(error);
      }
    }))
  }

  handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    this.requestNumber++;
    if (this.requestNumber == 1 && !this.isRefreshing) {
      this.refreshToken()
    }

    // if (!this.isRefreshing) {
    // this.isRefreshing = true;
    // this.refreshTokenSubject.next(null);

    // this.authService.refreshToken().then((token) => {
    //   this.isRefreshing = false;
    //   this.refreshTokenSubject.next(token);
    //   return next.handle(this.addToken(request, token));
    // })

    // } else if (this.isRefreshing) {
    return this.refreshTokenSubject.pipe(
      filter(token => token != null),
      take(1),
      switchMap(access_token => {
        return next.handle(this.addToken(request, access_token))
      }))

    // }
  }

  refreshToken() {
    this.isRefreshing = true;
    this.refreshTokenSubject.next(null);

    this.authService.refreshToken().then((token) => {
      this.isRefreshing = false;
      this.requestNumber = 0;
      this.refreshTokenSubject.next(token);
      // return next.handle(this.addToken(request, token));
    }).catch(error => {
      this.isRefreshing = false;
      this.requestNumber = 0;
      this.refreshTokenSubject.next(null);
      this.authService.logout();
    })
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }
}
