import { Actions, createEffect, ofType, Effect } from "@ngrx/effects";
import { mergeMap, map, catchError } from "rxjs/operators";
import { ApiServiceService } from "src/app/service/api-service.service";
import { Injectable } from "@angular/core";
import { BeginFetchTypes, SuccessFetchTypes, FailureFetchTypes } from "./types.actions";
import { FETCH_TYPES } from "./types.types";
import { of, Observable } from "rxjs";
import { Action } from "@ngrx/store";

@Injectable({
  providedIn: "root"
})
export class TypesEffects {
  constructor(private actions$: Actions, private globalApiService: ApiServiceService) {}

  fetchTypes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<BeginFetchTypes>(FETCH_TYPES.BEGIN),
      mergeMap(() =>
        this.globalApiService.getTypes().pipe(
          map(data => {
            return new SuccessFetchTypes(data["data"]);
          }),
          catchError(error => of(new FailureFetchTypes(error)))
        )
      )
    )
  );
}
