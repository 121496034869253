import { GlobalService } from "src/app/service/global.service";
import { CRUDService } from "src/app/service/crud.service";
import { Injectable } from "@angular/core";
import * as L from "leaflet";
import "leaflet-polylinedecorator";

@Injectable({
  providedIn: "root",
})
export class MarkerService {
  constructor(private crud: CRUDService, private global: GlobalService) {}

  makeMarkers(map: L.Map): void {
    this.crud.get(`${this.global.evacuationURL}/nodes`, this.global.headers).subscribe(
      (resp: any) => {
        if (resp.status == 200) {
          var geojson_points = resp.data;

          for (const c of geojson_points) {
            const lng = c.geometry.coordinates[0];
            const lat = c.geometry.coordinates[1];

            var color = "#3342FF";
            var radius = 5;
            var permanent = false;

            if (c.properties.isDestination) {
              color = "#2BC16D";
              radius = 8;
              permanent = true;
            }

            const marker = L.circleMarker([lat, lng], { radius: radius, color: color, fillColor: color, fillOpacity: 0.5 });
            marker.bindTooltip("<b>" + c.properties.ID + "</b>", { permanent: permanent });
            marker.addTo(map).on("click", (e) => {
              var clickedCircle = e.target;
              this.makeArrowLines(clickedCircle._map, clickedCircle._latlng);
            });
          }
        }
      },
      (error: any) => console.error(error)
    );
  }

  makeArrowLines(map: L.Map, latlng: any): void {
    this.crud.get(`${this.global.evacuationURL}/route`, this.global.headers, latlng).subscribe(
      (resp: any) => {
        if (resp.status == 200) {
          var geojson_features = resp.data;
          var i = 0;
          geojson_features
            .filter(function (feature: any) {
              return feature.geometry.type == "LineString";
            })
            .map(function (feature: any) {
              var coordinates = feature.geometry.coordinates;
              coordinates.forEach(function (coordinate: any) {
                coordinate.reverse();
              });
              var line = L.polyline(coordinates);
              var color = "#FF3333";
              addLine(map, line, feature, color);
              arrowify(map, line, feature, color);
              i++;
            });
        }
      },
      (error: any) => console.error(error)
    );
  }
}
function addLine(map: L.Map, line: any, feature: any, color: string): void {
  L.polylineDecorator(line, {
    patterns: [{ offset: 0, repeat: 10, symbol: L.Symbol.dash({ pixelSize: 5, pathOptions: { color: color, weight: 2 } }) }],
  }).addTo(map);
}

function arrowify(map: L.Map, line: any, feature: any, color: any): void {
  L.polylineDecorator(line, {
    patterns: [
      {
        offset: 0,
        repeat: 30,
        symbol: L.Symbol.arrowHead({
          pixelSize: 10,
          pathOptions: { color: color, fillOpacity: 0.7, weight: 2 },
        }),
      },
    ],
  }).addTo(map);
}
