import { Action } from "@ngrx/store";
import { FETCH_TYPES } from "./types.types";
import Type from "src/app/model/types.model";

export class BeginFetchTypes implements Action {
  readonly type = FETCH_TYPES.BEGIN;
}

export class SuccessFetchTypes implements Action {
  readonly type = FETCH_TYPES.SUCCESS;
  constructor(public payload: Type[]) {}
}

export class FailureFetchTypes implements Action {
  readonly type = FETCH_TYPES.FAILED;
  constructor(public payload: Error) {}
}

export type TypesAction = BeginFetchTypes | SuccessFetchTypes | FailureFetchTypes;
