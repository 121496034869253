import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CRUDService {
  constructor(public http: HttpClient) {
  }

  get(path: string, headers?: HttpHeaders, params?: any): Observable<Object> {
    return this.http.get(path, {
      headers: headers,
      params: params
    });
  }

  post(path: string, query?: object, headers?: HttpHeaders): Observable<Object> {
    return this.http.post(path, query, {
      headers: headers,
    });
  }

  put(path: string, query?: object, headers?: HttpHeaders): Observable<Object> {
    return this.http.put(path, query, {
      headers: headers,
    });
  }

  delete(path: string, query?: object, headers?: HttpHeaders): Observable<Object> {
    var options = {
      headers: headers || null,
      body: query || null
    }
    return this.http.delete(path, options);
  }

  patch(path: string, query?: object, headers?: HttpHeaders): Observable<Object> {
    return this.http.patch(path, query, {
      headers: headers,
    });
  }
}