import { Injectable } from "@angular/core";
import { RouterStateSnapshot, ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { Observable } from "rxjs";
import { OauthClientService } from "./oauth-client.service";
import { ApiServiceService } from "./api-service.service";

@Injectable({
  providedIn: "root"
})
export class AuthGuardService implements CanActivate {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;

  constructor(private oAuthService: OauthClientService, private globalAPIService: ApiServiceService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let token = sessionStorage.getItem("access_token");
    let userInfo = JSON.parse(sessionStorage.getItem("id_token_claims_obj"));
    let userRole = userInfo["role"];
    let userDepartment = userInfo["department"];
    if (token) {
      if (next.data.role && next.data.role != userRole) return false;

      if (next.data.department && next.data.department != userDepartment) return false;

      return true;
    } else {
      this.oAuthService.logout();
      return false;
    }
  }
}

@Injectable({
  providedIn: "root"
})
export class AuthGuardLoginService implements CanActivate {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;

  constructor(private oAuthService: OauthClientService, private router: Router, private globalAPIService: ApiServiceService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let token = sessionStorage.getItem("access_token");
    if (!token) {
      return true;
    } else {
      this.router.navigate(["/aditask"]);
      return false;
    }
  }
}
