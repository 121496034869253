import { Component } from "@angular/core";
import { GlobalService } from "./service/global.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  constructor(private globalService: GlobalService) {
    document.title = this.globalService.projectName[0] + this.globalService.projectName[1];
  }
}
