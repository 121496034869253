import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { OauthClientService } from "../service/oauth-client.service";
import { GlobalService } from "../service/global.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  constructor(private formBuilder: FormBuilder, private oAuthClientService: OauthClientService, public globalService: GlobalService) {}

  submitted: Boolean = false;
  loginForm: FormGroup;

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ["", Validators.required],
      password: ["", Validators.required]
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  login(loginForm, formDirective) {
    this.submitted = true;
    if (loginForm.invalid) {
      return;
    }
    this.oAuthClientService.login(loginForm.value.username, loginForm.value.password, formDirective);
  }
}
