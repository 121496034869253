import { Component, OnInit, AfterViewInit } from '@angular/core';
import * as L from 'leaflet';
import { MarkerService } from '../service/marker.service';

const iconRetinaUrl = 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png';
const iconUrl = 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png';
const shadowUrl = 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png';
const iconDefault = L.icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});
L.Marker.prototype.options.icon = iconDefault;

@Component({
  selector: 'app-evacuation-map',
  templateUrl: './evacuation-map.component.html',
  styleUrls: ['./evacuation-map.component.scss']
})
export class EvacuationMapComponent implements OnInit, AfterViewInit {
  private map: any;
  
  private initMap(): void {
    this.map = L.map('map', {
      center: [ 43.261372, -2.927536],
      zoom: 14
    });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.map);
  }

  constructor(private markerService: MarkerService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void { 
    this.initMap();
    this.markerService.makeMarkers(this.map);
    this.map.on('click', <LeafletMouseEvent>(e: any) => {
      this.markerService.makeArrowLines(this.map, e.latlng);
    });
  }

}
