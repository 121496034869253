import Type from "../../model/types.model";

export default class TypesState {
  types: Array<Type>;
  error: string;
  loading: boolean;
}

export const initializeState = (): TypesState => {
  return { types: Array<Type>(), error: null, loading: false };
};
