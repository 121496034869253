import { EvacuationMapComponent } from './evacuation-route/evacuation-map/evacuation-map.component';
import { EvacuationRouteComponent } from './evacuation-route/evacuation-route.component';
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { RouterModule } from "@angular/router";
import { routes } from "./app-routing";
import { DemoMaterialModule } from "./demo-material-module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FlexLayoutModule } from "@angular/flex-layout";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { LoginComponent } from "./login/login.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OAuthModule } from "angular-oauth2-oidc";
import { AuthGuardService, AuthGuardLoginService } from "./service/auth-guard.service";
import { JwtInterceptorService } from "./jwtsecurity/jwt-interceptor.service";
import { ErrorInterceptorService } from "./jwtsecurity/error-interceptor.service";
import { ToastrModule } from "ngx-toastr";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { StoreModule } from "@ngrx/store";
import { TypesReducer } from "./ngrxstore/types/types.reducer";
import { EffectsModule } from "@ngrx/effects";
import { TypesEffects } from "./ngrxstore/types/types.effect";
import { ScrollingModule } from "@angular/cdk/scrolling";

@NgModule({
  declarations: [AppComponent, LoginComponent, EvacuationRouteComponent, EvacuationMapComponent],
  imports: [
    BrowserModule,
    ScrollingModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" }),
    FlexLayoutModule,
    HttpClientModule,
    LeafletModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    OAuthModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: "toast-bottom-right",
      preventDuplicates: true,
    }),
    StoreModule.forRoot({ types: TypesReducer }),
    EffectsModule.forRoot([TypesEffects]),
  ],
  providers: [
    AuthGuardService,
    AuthGuardLoginService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
