import { EvacuationRouteComponent } from './evacuation-route/evacuation-route.component';
import { Routes } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { AuthGuardLoginService } from "./service/auth-guard.service";
import { LayoutComponent } from "./components/layout/layout.component";

export const routes: Routes = [
  {
    path: "evacuation",
    component: EvacuationRouteComponent,
  },
  {
    path: "",
    component: LoginComponent,
    canActivate: [AuthGuardLoginService],
  },
  {
    path: "aditask",
    children: [
      {
        path: "",
        loadChildren: () => import("./components/components.module").then((m) => m.ComponentsModule),
      },
    ],
  },
];