import { Injectable } from "@angular/core";
import { GlobalService } from "./global.service";
import { CRUDService } from "./crud.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ApiServiceService {
  constructor(private global: GlobalService, private crud: CRUDService) {}

  getPortalSuperUserInfo(): Observable<Object> {
    return this.crud.get(`${this.global.apiURL}/user/portal`, this.global.headers);
  }

  getUserByOauthID(oAUthID: string): Observable<Object> {
    return this.crud.get(`${this.global.apiURL}/user/${oAUthID}`, this.global.headers);
  }

  getDepartments(): Observable<Object> {
    return this.crud.get(`${this.global.apiURL}/department/`, this.global.headers);
  }

  getRoles(): Observable<Object> {
    return this.crud.get(`${this.global.apiURL}/role/`, this.global.headers);
  }

  getTypes() {
    return this.crud.get(`${this.global.apiURL}/type/`, this.global.headers);
  }
}
