import TypesState, { initializeState } from "./types.state";
import { createReducer, on, Action } from "@ngrx/store";
import { FETCH_TYPES } from "./types.types";
import { TypesAction } from "./types.actions";
export const initialState = initializeState();

export function TypesReducer(state: TypesState = initialState, action: TypesAction) {
  switch (action.type) {
    case FETCH_TYPES.BEGIN:
      return {
        ...state,
        loading: true
      };
    case FETCH_TYPES.SUCCESS:
      return {
        ...state,
        loading: false,
        types: action["payload"]
      };

    case FETCH_TYPES.FAILED:
      return {
        ...state,
        loading: false,
        error: action["error"].message
      };
    default:
      return state;
  }
}
